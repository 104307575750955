/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import './variables';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
// sub,
// sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
// table,
caption,
// tbody,
// tfoot,
// thead,
// tr,
// th,
// td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 14px;
  font: inherit;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-weight: normal;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
textarea {
  outline: none;
  vertical-align: middle;

  &::-moz-placeholder {
    color: #999;
  }

  &::-webkit-input-placeholder {
    color: #999;
  }

  &:-ms-input-placeholde {
    color: #999;
  }

  // 去除默认背景色
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  }
}

button {
  border: none;
  outline: none;
  user-select: none;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.9;
  }
}

img {
  vertical-align: middle;
  object-fit: cover;
  &::after {
    background-color: #f1f1f1;
    padding-top: 56%;
  }
}

a {
  transition: color, background-color, opacity 0.2s;
  text-decoration: none;
  color: $black;
  // display: block;
  transition: opacity 0.2s;
  // &:not([href]) {
  //   cursor: unset;
  // }
  &:hover {
    opacity: 0.75;
    color: $light-black;
  }
}

html {
  @for $i from 1 to 6 {
    h#{$i} {
      font-weight: 600;
      margin: 0;
    }
  }

  p {
    margin-bottom: 0;
  }

  b {
    font-weight: 600;
  }

  sub {
    bottom: 0;
  }

  sup {
    top: 0;
  }

  ol,
  ul,
  dl {
    margin-bottom: 0;
  }

  body {
    line-height: normal;
    font-family: -apple-system, 'PingFang SC', 'Microsoft YaHei', 'Hiragino Sans GB', 'STHeiti', 'Microsoft JhengHei',
      'Source Han Sans SC', 'Noto Sans CJK SC', 'Source Han Sans CN', 'Noto Sans SC', 'Source Han Sans TC',
      'Noto Sans CJK TC', 'WenQuanYi Micro Hei', SimSun, sans-serif, 'Helvetica Neue', 'Arial';
    // overflow-y: scroll;
    // overflow-x: hidden;
    font-size: 14px;
    // width: 100vw;
    min-width: 1024px;
  }
}

#__next {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}
