@import 'variables';
@import 'mixins';

.flex {
  $direction: (row), (row-reverse), (column), (column-reverse);
  $flow: (wrap), (nowrap);
  $justify-content: (start flex-start), (end flex-end), (center center), (between space-between), (around, space-around);
  $align-items: (start flex-start), (end flex-end), (center center), (baseline baseline), (stretch, stretch);

  @each $dr in $direction {
    @each $fw in $flow {
      &-#{$dr}-#{$fw} {
        display: flex;
        flex-flow: $dr $fw;
      }
    }
  }

  &-inline {
    display: inline-flex;
  }

  @each $justify-name, $justify in $justify-content {
    @each $align-name, $align in $align-items {
      &-#{$justify-name}-#{$align-name} {
        justify-content: $justify;
        align-items: $align;
      }
    }
  }

  &-auto {
    flex: auto;
  }

  &-shrink {
    flex-shrink: 0;
  }

  &-1 {
    flex: 1;
  }
}
