@use 'sass:math';
@import './variables';

@mixin defaultContentCss($fsz: 24px) {
  strong {
    font-weight: 600;
  }
  font-weight: 300;
  line-height: 1.7;
  figure {
    margin: 28px 0;
    img {
      margin: 0;
      margin-left: auto;
      margin-right: auto;
    }
    figcaption:not(:empty) {
      margin-top: 8px;
    }
    letter-spacing: -0.39px;
    color: #999;
    font-size: 14px;
  }
  @for $i from 1 through 6 {
    h#{$i} {
      font-size: 30px - $i * 2px;
      color: #000;
      padding: 2rem 0;
    }
  }
}

@mixin text-normal-style {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

@mixin Flex($Flex-flow: row nowrap, $justify-content: flex-start, $align-items: flex-start) {
  display: flex;
  flex-flow: $Flex-flow;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin multiline-ellipsis($line) {
  display: -webkit-box !important;
  word-break: break-word;
  text-overflow: ellipsis;
  box-orient: vertical;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -moz-orient: vertical;
  -webkit-line-clamp: $line;
  overflow: hidden;
}

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  word-break: break-word;
  white-space: initial;
  margin-right: -1em;
  padding-right: 1em;

  &::before {
    content: '...';
    position: absolute;
    right: 2px;
    bottom: 0;
  }

  &::after {
    content: '';
    position: absolute;
    right: 2px;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

@mixin clearfix() {
  &::before,
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin reset-image() {
  line-height: 1;
  font-size: 0;
}

@mixin solid-arrow($size: 4px, $color: #c8c8c8) {
  width: 0;
  height: 0;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-top: $size solid $color;
  margin-left: 4px;
}

@mixin text-stroke($width: 2px, $color: $theme-color) {
  $compatibility-width: 0.2px;
  -webkit-text-stroke: $width $color;
  text-shadow: 0 $compatibility-width $color, $compatibility-width 0 $color, -#{$compatibility-width} 0 $color,
    0 -#{$compatibility-width} $color;
}

@mixin angle-arrow($size: 8px, $direction: left) {
  display: inline-block;
  width: $size;
  height: $size;
  border-top: 1px solid currentColor;
  border-right: 1px solid currentColor;

  @if $direction==left {
    transform: rotate(45deg);
  } @else if $direction==up {
    transform: rotate(-45deg);
  } @else if $direction==right {
    transform: rotate(225deg);
  } @else {
    transform: rotate(-225deg);
  }
}

@mixin vertical-line($pad: 4px) {
  &::after {
    content: '|';
    color: currentColor;
    padding: 0 4px;
  }
}

@mixin divide-line($gap: 22px, $bgc: currentColor) {
  @include Flex(row nowrap, center, center);

  &::after,
  &::before {
    content: '';
    height: 1px;
    flex-grow: 1;
    background-color: $bgc;
  }

  &::before {
    margin-right: $gap;
  }

  &::after {
    margin-left: $gap;
  }
}

@mixin setPageCenter($max-w: $max-width, $gap: 20px) {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: $max-w;
  padding-left: $gap;
  padding-right: $gap;

  @media (max-width: $max-w - 1) {
    max-width: $max-w;
  }
}

// 分割线
@mixin line($color: #efefef, $width: 1px) {
  width: $width;
  transform: scaleX(0.5);
  background-color: $color;
}

@mixin explain-icon($width: 0.9em) {
  $scale: 2;
  $size: $width * $scale;
  $ratio: 0.8; // border 比例 为了让？与border贴的紧密一点

  border-radius: 50%;
  width: $width;
  flex: 0 0 $width;
  height: $width;
  font-size: $width;
  text-align: center;
  display: inline-block;
  margin: 0.2em;
  margin-top: 0;
  color: currentColor;
  vertical-align: middle;
  filter: opacity(0.5);
  position: relative;

  &::before {
    content: '?';
    font-weight: 400;
    border: 2px solid currentColor;
    font-size: $size * 0.8;
    line-height: $size * $ratio * $ratio * 1.1;
    width: $size * $ratio;
    height: $size * $ratio;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%) scale(math.div(1, $scale));
  }
}

@mixin btn-gradient() {
  background-image: linear-gradient(102deg, #e5be7f 6%, #d9a550 94%);
  color: #fff;
  width: 160px;
  padding: 11px;
  margin-right: 20px;
  font-weight: normal;
  text-align: center;
  transition: background 0.2s;
  cursor: pointer;

  &.disabled,
  &[disabled] {
    pointer-events: none;
    background-image: linear-gradient(102deg, #ccc 6%, #aaa 94%);
  }
}
