/**
* 参考规则 [类型｜区块｜使用场景｜命名空间]-[...分类、属性、描述、方位、状态]-[level]
* 1. 命名采用 短横拼接
* 2. 同类型、分类的放一起 (尽量简介、易读)
* 3. level 从0开始计数
* 4. 颜色如果 预期只有三种 primary、light、dark 来区分
* 5. 大小 xs sm md lg xl xxl （base基准）
* example:
* text-color-primary text-color-light、text-color-dark text-inverse-color
* zIndex-0 zIndex-1(这个特殊一点点)
* card-radius-sm card-radius-left-sm card-padding-h(水平 horizontal) card-padding-v(垂直 vertical)
*/
$reddish-orange: #f75c2f;
$theme-color-1: #c39134;
$theme-color-2: #d0a34e;
$theme-color-3: #f9f4e9;
$theme-color-4: #fdfcf9;
$input--border-color: #d8d8d8;
$white: #fff;

$black: #000000;
$light-black: #333333;
$brownish-grey: #666666;
$text-color-2: #7e84a3;
$text-color-3: #aaaaaa;
$text-color-4: #eeeeee;
$text-color-5: #f6f7f8;
$text-color-6: $white;
$text-color-7: #f5f6fa;

// 内容区 灰色背景
$content-gary-bgc: #eaeaea;
$content-header-bgc: $text-color-7;
$aside-dark-bgc: #162639;

$dark-bg-color: #878788;

$border-color: $text-color-4;
$border-color-light: $text-color-5;
$border-color-active: $theme-color-1;

$bgc-active: $theme-color-4;
$bgc-task: $text-color-7;

$status-success: #22d284;
$status-error: #f12a42;
$status-info: #1d5efe;
$status-warn: #fa9600;
$status-cancelled: #5a607f;

$up-color: $status-error;
$down-color: $status-success;

$theme-color: $theme-color-1;
$primary-color: $theme-color-1;

/* 透明度 */
$text-second-opacity: 0.85;

// 不限制最大宽
$max-width: 100%;
// 限制最小宽
$min-width: 1200px;

$screen-max-width: 1440px;
$max-inner-width: 952px;
$max-inner-width-lg: 1196px;

$main-nav-height: 56px;

$padding-base: 24px;

$zIndex-0: 1;
$zIndex-1: 10;
$zIndex-2: 100;
$zIndex-3: 1000;
$zIndex-4: 10000;
$zIndex-5: 100000;
$zIndex-infinity: 4294967296;

// 自定义的 公共类名前缀
$common-prefix: gf;

$easing-cubic: cubic-bezier(0.78, 0.14, 0.15, 0.86);

$font-main: 14px;
