@import 'mixins';

[data-class='editor-content'] {
  // max-width: $inner-width;
  // padding-top: 32px;
  text-align: justify;
  @include defaultContentCss(24px);

  @include text-normal-style();
  font-weight: normal;
  line-height: 1.69;
  color: #333333;
  blockquote {
    margin: 16px 3em;
    background-color: #f2f2f2;
    color: #666666;
    padding: 1em;
  }
  ol,
  ul {
    margin: 16px 0;
    padding-left: 40px;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  figure {
    margin: 52px 0;

    img {
      padding-bottom: 12px;
    }

    figcaption {
      font-size: 14px;
      color: #999;
      * {
        font-size: 14px !important;
        color: #999 !important;
      }
    }
  }

  img {
    max-width: 100%;
    object-fit: contain;
    //padding: 36px 0;
    margin: auto;
  }

  .image-wrapper {
    margin: 16px 0;
    text-align: center;
    border: 0;
    border: 1px solid #ddd;
  }
  .image-desc {
    display: block;
    font-size: 14px;
    color: #999;
  }
  .image-desc * {
    font-size: 14px !important;
    color: #999 !important;
  }
  figcaption {
    display: none;
  }
  .rich_media_title {
    padding: 32px 0;
    font-size: 22px;
  }
  .rich_media_meta_list,
  .article-tag__list {
    line-height: 1.7;
    font-size: 16px;
  }

  p,
  section {
    margin-top: 0;
    margin-bottom: 16px;
    min-height: 27px;
    font-size: 16px;
    line-height: 1.7;
  }
  button {
    border: none;
    outline: none;
    user-select: none;
    font-family: Helvetica, PingFang SC, STHeiti, Microsoft Yahei, sans-serif;
  }
  button:focus {
    border: none;
    outline: none;
  }
  div {
    line-height: 1.7;
    font-size: 16px;
  }

  .mce-content-body {
    color: #333;
  }
  blockquote {
    margin: 16px 3em;
    background-color: #f2f2f2;
    color: #666666;
    padding: 1em;
  }
  #tinymce {
    font-family: PingFang SC, Microsoft YaHei, Helvetica Neue For Number, -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto, Hiragino Sans GB, Helvetica Neue, Helvetica, Arial, sans-serif;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 32px 0;
    font-weight: 500;
  }
}
