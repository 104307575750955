@import 'variables';
@import 'mixins';

.#{$common-prefix} {
  &-btn {
    &-gradient {
      @include btn-gradient();
    }

    &-lg {
      width: 186px;
    }

    &-md {
      width: 160px;
    }

    &-sm {
      width: 80px;
    }
  }
}
