@import 'variables';
@import 'mixins';

.pageInner {
  max-width: $max-inner-width;
  margin: auto;
}
.pageInnerlg {
  max-width: $max-inner-width-lg;
  margin: auto;
}
