// 全局类名处理
html {
  // 全局灰色处理
  // filter: grayscale(100%);
  // filter: gray;
  // 提高权重，优于ant 默认样式
  // 全局统一样式头像
  .#{$common-prefix} {
    &-avatar {
      color: #fff;
      background-color: #c39134;
      vertical-align: middle;
    }

    &-is-center {
      text-align: center;
    }
  }
  .ant-popover {
    position: fixed;
  }
  .ReactModal__Body--open {
    overflow: hidden;
  }
}
.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}
