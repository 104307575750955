// 这边的样式都是为了和老项目保持一致

.customOverlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.customModal {
  background: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  &:focus {
    outline: none;
  }
  // overflow-y: unset !important;
}
.customCloseButton {
  // position: static !important;
  top: 20px;
  right: 20px;
  // bottom: -60px;
  // transform: translate(50%);
  // right: 50% !important;
  // margin: 24px auto 0 auto;
  // width: 40px;
  // height: 40px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // border: 2px solid #fff !important;
  // border-radius: 50%;
  font-size: 30px;
  color: #aaa;
}
