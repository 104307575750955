@import 'variables';
@import 'mixins';

.text {
  &-ellipsis {
    @include text-ellipsis();
  }

  &-nowrap {
    white-space: nowrap;
  }

  &-center {
    text-align: center;
  }

  &-second {
    color: $text-color-2;
  }

  &-ln-1 {
    @include multiline-ellipsis(1);
  }

  &-ln-2 {
    @include multiline-ellipsis(2);
  }

  &-ln-3 {
    @include multiline-ellipsis(3);
  }

  &-din {
    font-family: 'DINAlternate';
    letter-spacing: -0.05em;
  }
}
